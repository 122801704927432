import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiselectWithButton = _resolveComponent("MultiselectWithButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingData }, null, 8, ["loading"]),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isLoadingData)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            value: _ctx.items,
            breakpoint: "1267px",
            dataKey: "order_no",
            rows: _ctx.rows,
            first: _ctx.first,
            "onUpdate:first": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.first) = $event)),
            selection: _ctx.selectedRow,
            "onUpdate:selection": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedRow) = $event)),
            selectionMode: _ctx.showSelectionCheckbox ? 'single' : undefined,
            paginator: true,
            sortField: _ctx.sortField,
            sortOrder: _ctx.sortOrder,
            scrollable: "",
            responsiveLayout: "stack",
            rowsPerPageOptions: [25, 50, 100],
            onPage: _ctx.pageClick,
            onSort: _cache[17] || (_cache[17] = ($event: any) => (_ctx.sortData($event))),
            onFilter: _ctx.handleFilter,
            onRowClick: _ctx.rowClick,
            filters: _ctx.filters,
            "onUpdate:filters": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.filters) = $event)),
            filterDisplay: "menu",
            paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} entries",
            class: _normalizeClass(["p-datatable-sm tablehead-no-border-bc", {
          'datatable-only-header column-header-separator': !_ctx.isResultView,
        }]),
            removableSort: ""
          }, {
            empty: _withCtx(() => [
              _createTextVNode(" No records found ")
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "order_no",
                header: "Order No",
                "header-class": "text-center justify-content-center",
                "body-class": "text-center w-1",
                showAddButton: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.order_no), 1)
                ]),
                filter: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    type: "text",
                    modelValue: _ctx.selectedFilterId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilterId) = $event)),
                    class: "p-column-filter",
                    placeholder: "Search by Order No"
                  }, null, 8, ["modelValue"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearSalesSearchBar()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchFieldServiceData(false)))
                  })
                ]),
                _: 1
              }, 8, ["sortable"]),
              (_ctx.customerIndex == null && _ctx.showCustomer)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    field: "cust_name",
                    header: "Customer",
                    showAddButton: false,
                    showFilterMatchModes: false,
                    showFilterOperator: false,
                    sortable: _ctx.isResultView
                  }, {
                    filter: _withCtx(() => [
                      _createVNode(_component_MultiselectWithButton, {
                        value: _ctx.selectedFilterCusts,
                        options: _ctx.searchedFilterCusts,
                        filterFields: ['name', 'cust_id'],
                        filter: true,
                        optionLabel: "name",
                        placeHolder: "Enter Customer ID or Name",
                        maxSelectedLabels: 2,
                        btnLabel: "Search",
                        onBtnCallback: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterCust())),
                        onUpdateFilterValue: _cache[4] || (_cache[4] = 
                (filterValue) => (_ctx.selectedFilterCusts = filterValue)
              ),
                        onFilter: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleCustomerSelectFilter($event)))
                      }, null, 8, ["value", "options"])
                    ]),
                    filterclear: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Clear",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clearCustomerSearch()))
                      })
                    ]),
                    filterapply: _withCtx(() => [
                      _createVNode(_component_Button, {
                        type: "button",
                        label: "Search",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterCust()))
                      })
                    ]),
                    _: 1
                  }, 8, ["sortable"]))
                : _createCommentVNode("", true),
              (_ctx.isResultView)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 1,
                    field: "service_unit",
                    header: "Serviced By",
                    "header-class": "text-center justify-content-center",
                    "body-class": "text-left",
                    sortable: _ctx.isResultView
                  }, null, 8, ["sortable"]))
                : _createCommentVNode("", true),
              (_ctx.isResultView)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 2,
                    field: "order_type",
                    "header-class": "text-center justify-content-center",
                    "body-class": "text-left",
                    header: "Type"
                  }, {
                    body: _withCtx(({ data }) => [
                      _createTextVNode(_toDisplayString(_ctx.reportType(data.order_type)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_Column, {
                field: "conv_service_date",
                header: "Service Date",
                bodyStyle: "text-align: center",
                showAddButton: false,
                showFilterMatchModes: true,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatStringDate(data.conv_service_date) || ""), 1)
                ]),
                filter: _withCtx(() => [
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.selectedFilterDate,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedFilterDate) = $event)),
                    dateFormat: "mm-dd-yy",
                    class: "p-column-filter",
                    selectionMode: "range",
                    placeholder: "Select Date Range"
                  }, null, 8, ["modelValue"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clearDateFilter()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchFieldServiceData(false)))
                  })
                ]),
                _: 1
              }, 8, ["sortable"]),
              _createVNode(_component_Column, {
                field: "status",
                header: "Status",
                "header-class": "text-center justify-content-center",
                showAddButton: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                sortable: _ctx.isResultView
              }, {
                filter: _withCtx(() => [
                  _createVNode(_component_MultiselectWithButton, {
                    value: _ctx.selectedFilterStatus,
                    options: _ctx.status,
                    optionLabel: "status",
                    optionValue: "initial",
                    dataKey: "initial",
                    placeHolder: "Filter by Status",
                    filter: true,
                    filterMatchMode: "startsWith",
                    style: { width: '200px' },
                    btnLabel: "Apply",
                    onBtnCallback: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fetchFieldServiceData(false))),
                    onUpdateFilterValue: _cache[12] || (_cache[12] = 
                (filterValue) => (_ctx.selectedFilterStatus = filterValue)
              )
                  }, null, 8, ["value", "options"])
                ]),
                filterclear: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Clear",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.clearStatusSearchBar()))
                  })
                ]),
                filterapply: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Apply",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchFieldServiceData(false)))
                  })
                ]),
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", {
                      class: _normalizeClass({ 'status-column': slotProps.data.status })
                    }, _toDisplayString((_ctx.statusLabel as any)[slotProps.data.status]), 3)
                  ])
                ]),
                _: 1
              }, 8, ["sortable"]),
              (_ctx.isResultView)
                ? (_openBlock(), _createBlock(_component_Column, { key: 3 }, {
                    body: _withCtx(({ data }) => [
                      _withDirectives(_createVNode(_component_Button, {
                        id: "downloadButton",
                        title: "Download",
                        icon: _ctx.pdfIcon(data.order_no),
                        onClick: ($event: any) => (_ctx.getPDF(data.order_no, data.order_type))
                      }, null, 8, ["icon", "onClick"]), [
                        [
                          _directive_tooltip,
                          'Generate PDF #' + data.order_no,
                          void 0,
                          { left: true }
                        ]
                      ]),
                      _createTextVNode("   "),
                      _withDirectives(_createVNode(_component_Button, {
                        id: "emailButton",
                        icon: "pi pi-envelope",
                        title: "Email",
                        onClick: ($event: any) => (_ctx.handleEmailPDF(data))
                      }, null, 8, ["onClick"]), [
                        [
                          _directive_tooltip,
                          'Email PDF Field Service #' + data.order_no,
                          void 0,
                          { left: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["value", "rows", "first", "selection", "selectionMode", "sortField", "sortOrder", "onPage", "onFilter", "onRowClick", "filters", "class"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailDialog,
      header: 'Email Field Service #' + _ctx.fsoIdToEmail,
      onHide: _cache[19] || (_cache[19] = 
        (isHidden) => {
          _ctx.showEmailDialog = isHidden;
        }
      ),
      fileId: _ctx.fsoIdToEmail,
      fileName: _ctx.fsoIdToEmail,
      onOnEmail: _ctx.sendEmail,
      contacts: _ctx.customerContacts
    }, null, 8, ["show", "header", "fileId", "fileName", "onOnEmail", "contacts"])
  ]))
}