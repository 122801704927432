import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0 justify-content-center ml-2 mr-2" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldServiceHeaderButtons = _resolveComponent("FieldServiceHeaderButtons")!
  const _component_FieldServiceDataWrapper = _resolveComponent("FieldServiceDataWrapper")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_FieldServiceHeaderButtons, {
            title: "Service History",
            "new-button-text": "New Field Service",
            "show-print-button": true,
            showClearFilterButton: true,
            lastUpdated: _ctx.lastUpdated,
            onClearFilters: _ctx.clearFilters,
            onPrintButtonClicked: _ctx.printExportData,
            onNewButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSales(false)))
          }, null, 8, ["lastUpdated", "onClearFilters", "onPrintButtonClicked"]),
          _createVNode(_component_FieldServiceDataWrapper, {
            ref: "fieldServiceTable",
            isResultView: _ctx.isResultView,
            clearFilters: _ctx.clearFilters,
            printExportData: _ctx.printExportData,
            maxExportRows: 1000,
            onLastUpdated: _ctx.isDataUpdated
          }, null, 8, ["isResultView", "clearFilters", "printExportData", "onLastUpdated"])
        ]),
        _: 1
      })
    ])
  ]))
}