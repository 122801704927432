
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import FieldServiceDataWrapper from "@/components/FieldService/FieldServiceDataWrapper.vue";
import FieldServiceHeaderButtons from "@/components/FieldService/FieldServiceHeaderButtons.vue";
import { FieldServiceObject } from "@/types/state/fieldServices";

export default defineComponent({
  name: "FieldService",
  components: {
    FieldServiceDataWrapper,
    Card,
    FieldServiceHeaderButtons,
  },
  computed: {
    ...mapState(["shipControl", "salesInquiry"]),
    ...mapGetters({
      getOrders: "sales/getOrders",
      getRangeEnd: "sales/getRangeEnd",
      getFirstRow: "sales/getFirstRow",
      getFieldServices: "fieldServices/getFieldServices",
    }),
  },
  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
    };
  },
  mounted() {
    this.first = this.getFirstRow;
  },
  methods: {
    ...mapActions({
      addNewFieldService: "fieldServices/addNewFieldService",
    }),
    printExportData() {
      (this.$refs as any).fieldServiceTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).fieldServiceTable.clearAllFiltersAndFetch();
    },
    openSales(edit: boolean) {
      const newOrders = this.getFieldServices.filter(
        (fso: FieldServiceObject) =>
          fso.old_record.order_no && fso.old_record.order_no.startsWith("New"),
      );
      const newOrderCount = newOrders.length + 1;
      this.addNewFieldService(`New-${newOrderCount}`);
      this.$router.push(`/fieldservices/New-${newOrderCount}`);
      // this.showSalesDialog = true;
      // this.readOnly = edit;
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
